@import '../../styles/dimensions';
@import '../../styles/breakpoints';

.gridContainer {
    margin: $gap-x-large auto;
    max-width: 800px;
    padding-bottom: $gap-x-large;

    @include screen-max-width('lg') {
        margin-top: 0;
    }
}

.grid {
    display: grid;
    gap: $gap-small;
    grid-template-columns: repeat(3, minmax(200px, 1fr));

    @include screen-max-width('sm') {
        gap: $gap-x-small;
        grid-template-columns: repeat(3, minmax(80px, 1fr));
    }
}
